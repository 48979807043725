import { Grid } from '@mui/material'
import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import axios from 'axios';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { L1, L2, L3 } from '../../SvgCollection';
import axios from 'axios';
import $ from 'jquery'
import io from "socket.io-client";
import consts from '../../constant';



function Tradeleft({ pair, setPrices, prices1 }) {
    const [value, setValue] = useState(0);
    const [orderBookPrice, setOrderBookPrice] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [valueTab2, setValueTab2] = useState(0);

    const handleChangeTab2 = (event, newValue) => {
        setValueTab2(newValue);
    };

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }


    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [num, setNum] = useState([
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
    ])

    const midpoint = Math.floor(num.length / 2);

    const [orderBookBits, setOrderBookBits] = useState([])
    const [orderBookAsks, setOrderBookAsks] = useState([])

    const [oldPair, setOldPair] = useState("")

    const getCurrentOrderBook = async () => {
        try {
            const { data } = await axios.get(`https://api.binance.com/api/v3/depth?symbol=${pair?.split("_")?.join("")}&limit=100`);
            if (data?.bids?.length > 0 || data?.asks?.length > 0) {
                setOrderBookBits(data?.bids?.slice(0, 20))
                setOrderBookAsks(data?.asks?.slice(0, 20))
            }
        } catch (error) {
            console.log("🚀 ~ getCurrentOrderBook ~ error:", error)
        }
    }
    const [orderbookSoc, setOrderBookSoc] = useState()

    const orderBookSocket = async () => {
        try {
            if (orderbookSoc) {
                // Unsubscribe from the previous WebSocket subscription
                const unsubscribeMsg = {
                    method: "UNSUBSCRIBE",
                    params: [
                        `${oldPair?.split("_")?.join("")?.toLowerCase()}@depth10`,
                        `${oldPair?.split("_")?.join("")?.toLowerCase()}@ticker`
                    ],
                    id: 2
                };
                orderbookSoc.send(JSON.stringify(unsubscribeMsg));
                orderbookSoc.close();
            }
    
            const ws = new WebSocket("wss://stream.binance.com:9443/ws");
            setOrderBookSoc(ws);
    
            ws.onopen = () => {
                const symbols = `${pair?.split("_")?.join("")?.toLowerCase()}@depth10`;
                const pricedata = `${pair?.split("_")?.join("")?.toLowerCase()}@ticker`;
                const priceApicall = {
                    method: "SUBSCRIBE",
                    params: [symbols, pricedata],
                    id: 1
                };
                ws.send(JSON.stringify(priceApicall));
            };
    
            ws.onmessage = function (event) {
                const json = JSON.parse(event.data);
                // console.log(json, "jsonjson");
    
                try {
                    if (!json?.s || json?.s?.toUpperCase() !== pair?.split("_")?.join("").toUpperCase()) {
                        return; // Skip data that doesn't match the selected pair
                    }
    
                    if (json?.p === pair?.split("_")?.join("").toUpperCase()) {
                        setOrderBookPrice(Number(json?.c).toFixed(6));
                    }
    
                    if (json?.p) {
                        $(`.pricess-${json?.s}`).html(`<span class=${Number(json?.P).toFixed(2) > 0 ? 'tr-green' : 'tr-red'} > ${Number(json?.c).toFixed(6)}</span>`);
                    }
    
                    var bits = json?.bids;
                    var asks = json?.asks;
                    if (bits?.length > 0) {
                        setOrderBookBits((prevArray) => [
                            ...prevArray?.slice(10), // Remove the first 10 elements
                            ...bits,
                        ]);
                    }
                    if (asks?.length > 0) {
                        setOrderBookAsks((prevArray) => [
                            ...prevArray?.slice(10), // Remove the first 10 elements
                            ...asks,
                        ]);
                    }
                } catch (error) {
                    console.log(error, 'err');
                }
            };
    
            ws.onerror = (error) => {
                console.error('WebSocket Error:', error);
            };
    
            ws.onclose = () => {
                console.log('WebSocket Connection Closed');
            };
    
            return () => {
                console.log('Cleaning up WebSocket connection');
                ws.close();
            };
        } catch (error) {
            console.log("🚀 ~ orderBookSocket ~ error:", error);
        }
    };

    const orderBookSocketOwn = async () => {
        try {

            // const socket = io(`${consts?.socketUrl}`, { path: "/socket" }); // for live
            const socket = io(`${consts?.socketUrl}`);  // for local

            socket.on('connect', () => {
                console.log('Connected to backend WebSocket');
                const request = {
                    type: "trade",
                    symbol: pair?.toUpperCase(),
                };
                socket.emit('orderbooks', request);
            });

            socket.on('orderbooks', (data) => {

                if (data?.success) {
                    
                    var bits = data?.data?.bids;
                    var asks = data?.data?.asks;
                   
                    if (bits?.length > 0) {
                        setOrderBookBits((prevArray) => [
                            ...prevArray,
                            ...bits,
                        ]);
                    }
                    if (asks?.length > 0) {
                        setOrderBookAsks((prevArray) => [
                            ...prevArray,
                            ...asks,
                        ]);
                    }
                    // const { buytrade, selltrade, symbol } = data?.data;
                    // setOrderBookPrice(data?.message);
                    // setOrderBookBits(buytrade);
                    // setOrderBookAsks(selltrade);
                } else {
                    console.log('Error:', data?.message);
                }
            });

            socket.on('disconnect', () => {
                console.log('Disconnected from backend WebSocket');
            });

        } catch (error) {
            console.log("🚀 ~ orderBookSocket ~ error:", error);
        }
    };

    useEffect(() => {
        if (pair) {
            if (oldPair) {
                orderbookSoc.close()
            }
            setOldPair(pair)
            setOrderBookAsks([])
            setOrderBookBits([])
            getCurrentOrderBook()
            orderBookSocket()
            orderBookSocketOwn()
        }
    }, [pair])


    return (
        <div className='trade-left orderBook'>
            <Grid container className='mgrid'>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={{ width: '100%' }}>
                        <Box>
                            <Tabs value={valueTab2} onChange={handleChangeTab2} aria-label="basic tabs example" className='tabs-trade' style={{ marginBottom: "10px" }}>
                                <Tab label="Order Book" {...a11yProps(0)} />
                                {/* <Tab label="Recent Trades" {...a11yProps(1)} /> */}
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={valueTab2} index={0}>
                            <Box sx={{ width: '100%' }}>
                                <Box className='box-cls'>
                                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                        <Tab label={<L1 />} {...a11yProps(0)} />
                                        <Tab label={<L2 />} {...a11yProps(1)} />
                                        <Tab label={<L3 />}{...a11yProps(2)} />
                                    </Tabs>
                                </Box>
                                <CustomTabPanel value={value} index={0} className='cust-box'>
                                    <TableContainer className='trade-left-table'>
                                        <Table aria-label="simple table" stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align='left'>Price({pair?.split("_")[1]}) </TableCell>
                                                    <TableCell align='right'>Amount({pair?.split("_")[0]})</TableCell>
                                                    <TableCell align='right'>Total</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {orderBookBits?.length > 0 && orderBookBits?.map((row, ind) => (
                                                    <TableRow
                                                        key={ind}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, marginBottom: "1px" }}
                                                        className={'tr-red'}
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => { setPrices(row) }}
                                                    >
                                                        <TableCell component="th" scope="row" align='left' className='bg-red' >
                                                            {row?.length > 0 && Number(row[0])?.toFixed(4)}
                                                            {/* {row && Number(row?._id)?.toFixed(4)} */}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" align='right' >
                                                            {row?.length > 0 && Number(row[1])?.toFixed(4)}
                                                            {/* {row && Number(row?.volume?.toFixed(4))} */}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" align='right'>
                                                            {row?.length > 0 && (Number(row[0]) * Number(row[1]))?.toFixed(4)}
                                                            {/* {row && (Number((row?._id)) * Number(row?.volume))?.toFixed(4)} */}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <div style={{ color: "#02ab78", fontSize: '14px' }} className={`prices-${pair?.split("_")?.join("")}`} >{orderBookPrice}</div>
                                    {/* <div style={{ color: "#02ab78", fontSize: '14px' }} >{Number(prices1).toFixed(3)}</div> */}
                                    <TableContainer className='trade-left-table'>
                                        <Table aria-label="simple table" stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align='left'>Price({pair?.split("_")[1]}) </TableCell>
                                                    <TableCell align='right'>Amount({pair?.split("_")[0]})</TableCell>
                                                    <TableCell align='right'>Total</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {orderBookAsks?.length > 0 && orderBookAsks?.map((row, ind) => (
                                                    <TableRow
                                                        key={ind}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        className={'tr-green'}
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => { setPrices(row) }}
                                                    >
                                                        <TableCell component="th" scope="row" align='left' className='bg-green'>
                                                            {row?.length > 0 && Number(row[0])?.toFixed(4)}
                                                            {/* {row && Number(row?._id?.toFixed(4))} */}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" align='right'>
                                                            {row?.length > 0 && Number(row[1])?.toFixed(4)}
                                                            {/* {row && Number(row?.volume?.toFixed(4))} */}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" align='right'>
                                                            {row?.length > 0 && (Number(row[0]) * Number(row[1]))?.toFixed(4)}
                                                            {/* {row && (Number((row?._id)) * Number(row?.volume))?.toFixed(4)} */}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                </CustomTabPanel>
                                <CustomTabPanel value={value} index={1}>
                                    <TableContainer className='trade-left-table tb2'>
                                        <Table aria-label="simple table" stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align='left'>Price(USDT) </TableCell>
                                                    <TableCell align='right'>Amount(ETH)</TableCell>
                                                    <TableCell align='right'>Sum(USDT)</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {orderBookBits?.length > 0 && orderBookBits?.map((row, ind) => (
                                                    <TableRow
                                                        key={ind}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                                                        className={'tr-red'}
                                                    >
                                                        <TableCell component="th" scope="row" align='left' >
                                                            {/* {row.n1} */}
                                                            {row?.length > 0 && Number(row[0])?.toFixed(4)}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row" align='right'>
                                                            {/* {row.n1} */}
                                                            {row?.length > 0 && Number(row[1])?.toFixed(4)}

                                                        </TableCell>
                                                        <TableCell component="th" scope="row" align='right'>
                                                            {/* {row.n1} */}
                                                            {row?.length > 0 && (Number(row[0]) * Number(row[1]))?.toFixed(4)}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CustomTabPanel>
                                <CustomTabPanel value={value} index={2}>
                                    <TableContainer className='trade-left-table tb2'>
                                        <Table aria-label="simple table" stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align='left'>Price(USDT) </TableCell>
                                                    <TableCell align='right'>Amount(ETH)</TableCell>
                                                    <TableCell align='right'>Sum(USDT)</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {orderBookAsks?.length > 0 && orderBookBits?.map((row, ind) => (
                                                    <TableRow
                                                        key={ind}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        className={'tr-green'}
                                                    >
                                                        <TableCell component="th" scope="row" align='left' >
                                                            {/* {row.n1} */}
                                                            {row?.length > 0 && Number(row[0])?.toFixed(4)}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row" align='right'>
                                                            {/* {row.n1} */}
                                                            {row?.length > 0 && Number(row[1])?.toFixed(4)}

                                                        </TableCell>
                                                        <TableCell component="th" scope="row" align='right'>
                                                            {/* {row.n1} */}
                                                            {row?.length > 0 && (Number(row[0]) * Number(row[1]))?.toFixed(4)}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CustomTabPanel>
                            </Box>
                        </CustomTabPanel>
                        <CustomTabPanel value={valueTab2} index={1}>
                            Item Two
                        </CustomTabPanel>

                    </Box>


                </Grid>
            </Grid >
        </div >
    )
}

export default Tradeleft
